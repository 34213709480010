import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Header.scss';

const Header = () => {


    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    const [isActive, setIsActive] = useState(false);
    const [scrollY, setScrollY] = useState(0);

    const toggleLanguage = () => {
        const newLanguage = currentLanguage === 'en' ? 'tr' : 'en';
        setCurrentLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
        setIsActive(!isActive)
        localStorage.setItem('language', newLanguage);
    };

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`${scrollY > 40 ? 'header-hidden' : ''}`}>
            <div className='switch-border'>
                <div className={`switch ${currentLanguage === 'tr' ? 'active' : ''}`} onClick={toggleLanguage}>
                    <div className="slider"></div>
                    <div className="en">EN</div>
                    <div className="tr">TR</div>
                </div>
            </div>
        </header>
    );
};

export default Header;
