import { ParallaxBanner } from "react-scroll-parallax";
import Background from "../assets/images/covenlabs-bg.jpg";
import { H2_LG, P_2MD } from "../components/Typography";
import { useTranslation } from "react-i18next";

const Intro = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="overflow-hidden section-height h-screen w-full">
        <ParallaxBanner
          layers={[{ image: Background, speed: -25 }]}
          className="w-full h-full animated introZoom"
        >
          <div className="absolute inset-0 flex flex-col items-center justify-center z-0 after:gradient-3 after:absolute after:w-full after:left-0 after:bottom-0 after:h-[655px] after:-z-10 before:gradient-3 before:absolute before:w-full before:left-0 before:top-0 before:h-[300px] before:-z-10 before:rotate-180">
            <div className="container">
              <H2_LG className="font-heading text-white text-center animated introTextLeft animated-delay-5">
                <span>{t('WELCOME_TO')}</span>
              </H2_LG>
              <div className="mb-4"></div>
              <H2_LG className=" font-heading text-white text-center animated introTextRight animated-delay-5">
                <span>{t('WELCOME_TO2')}</span>
              </H2_LG>
              <P_2MD className="text-center text-white mt-5 animated fadeInUp animated-delay-7 lg:!leading-6">
                {t('WelcomeMessage')}
              </P_2MD>
              {/* <P_2MD className="text-center italic flex justify-center gap-3 text-amethyst-smoke mt-5 animated fadeInUp animated-delay-9 lg:!leading-6">
                Powered by Ethereum
                <img src={EthLogo} alt="Ethereum" />
              </P_2MD> */}
            </div>
          </div>
        </ParallaxBanner>
      </section>
    </>
  );
};

export default Intro;
