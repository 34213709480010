import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./Email.scss";
import { useState } from "react";
import Loader from "../../assets/images/loader.svg";
import { useTranslation } from "react-i18next";

const EmailForm = ({
  config,
  btnText,
  btnClass,
  inputClass,
  className,
  modalHandle,
}) => {
  const [error, setError] = useState("");

  const { t } = useTranslation();

  const EmailFormSchema = Yup.object().shape({
    email: Yup.string()
      .email(config.validation.email)
      .required(config.required.email),
  });

  const topic = btnText === "SUBSCRIBE" ? "NEWSLETTER" : "WAITLIST";
  const onSubmit = (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    const response = axios.post(config.api, values, {
      headers: {
        'channel': "CLUB",
        'language': localStorage.getItem('language') || 'tr'
      },

    });

    response
      .then((responseData) => {
        if (responseData.data.success === true) {
          modalHandle();
          resetForm();
        }
        else {
          setError(config.error);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(config.error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onChange = () => setError("");

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          topic,
        }}
        validationSchema={EmailFormSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isSubmitting, values }) => (
          <Form className="emailform w-full">
            <div
              className={`relative ${className} ${(errors.email && touched.email && values.email !== "") ||
                (touched.email && error !== "")
                ? "is-invalid"
                : ""
                }`}
            >
              {(errors.email && touched.email && values.email !== "") ||
                (touched.email && error !== "") ? (
                <div className="absolute text-[#ff6161] opacity-70 text-[14px] -bottom-6">
                  {errors.email ?? error}
                </div>
              ) : null}
              <Field
                name="email"
                className={`${inputClass} emailform-input w-full lg:py-8 py-5 lg:pl-8 pl-5 lg:pr-[290px] pr-[190px] lg:rounded-[30px] rounded-[20px] text-silver-sand placeholder:text-[#808080] lg:text-base lg:leading-[26px] leading-[22px] text-base-m`}
                type="text"
                placeholder={`E-Mail ${t('Address')}`}
                onKeyUp={onChange}
              />
              <button
                type={"submit"}
                className={`absolute right-0 mr-[1px] mt-[1px] emailform-btn inline-flex lg:rounded-[30px] rounded-[20px] cursor-pointer top-0 text-center justify-center lg:text-[27px] text-lg leading-[34px] z-[1] overflow-hidden ${btnClass}`}
                style={{ borderWidth: "0 0 0 1px" }}
                disabled={isSubmitting}
              >
                <span className="font-heading">
                  {isSubmitting ? <img src={Loader} alt="" /> : btnText}
                </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EmailForm;
