import Header from "./layout/header/Header";
import Footer from "./layout/footer/Footer";
import Benefits from "./sections/Benefits";
import Genesis from "./sections/Genesis";
import Intro from "./sections/Intro";

function App() {
  return (
    <>
      <Header />
      <Intro />
      <Genesis />
      <Benefits />
      <Footer />
    </>
  );
}

export default App;
