import React from "react";
import ReactDOM from "react-dom/client";
import { ParallaxProvider } from "react-scroll-parallax";
import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import "./assets/scss/global.scss";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
const userLanguage = navigator.language.split('-')[0];
const language = localStorage.getItem('language');

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require('./locales/en/translation.json')
    },
    tr: {
      translation: require('./locales/tr/translation.json')
    }
  },
  lng: language ? language : userLanguage === 'tr' ? 'tr' : 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
root.render(
  <ParallaxProvider>
    <App />
  </ParallaxProvider>
);
