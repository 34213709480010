import { Fragment, useRef, useState } from "react";
import { Formik, Form } from "formik";
import axios from "axios";
import { H2_MD, P_SM } from "../Typography";
import "./Waitlist.scss";
import SuccessModalContainer from "../successModal/SuccessModalContainer";
import WaitlistValidate from "./WaitlistValidate";
import WaitlistInput from "./WaitlistInput";
import Loader from "../../assets/images/loader.svg";
import { waitlist } from "../../constants";
import { useTranslation } from "react-i18next";

const WaitlistForm = ({ closeModalState, btnText }) => {
    const modalRef = useRef();
    const formRef = useRef();
    const successModalRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState("");
    const topic = btnText === "SUBSCRIBE" ? "NEWSLETTER" : "WAITLIST";
    const viewHeight = window.visualViewport.height;

    const onSubmit = (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const response = axios.post(waitlist.api, values, {
            headers: {
                'channel': "CLUB",
                'language': localStorage.getItem('language') || 'tr'
            }
        });

        response
            .then((responseData) => {
                if (responseData.data.success === true) {
                    formZoomOut(formRef);
                    setIsOpen(true);
                    resetForm();
                }
                else {
                    setError(waitlist.error);
                }
            })
            .catch((err) => {
                setError(waitlist.error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };
    const onChange = () => setError("");

    const closeModal = () => {
        modalRef.current.classList.add("fadeOut");
        modalRef.current.style.animationDelay = "0.3s";
    };

    const formZoomOut = (ref) => {
        ref.current.classList.add("zoomOut");
        ref.current.style.animationDelay = "0s";
    };

    const disposeModal = (formRef) => {
        closeModal();
        formZoomOut(formRef);
        closeModalState();
    };

    const displayHandle = (e) => {
        if (e.target.closest(".waitlistform-container") === null && !isOpen)
            disposeModal(formRef);
    };

    const scrollHandle = () => {
        const isKeyboardOpen = viewHeight > window.visualViewport.height;
        if (!isKeyboardOpen) {
            if (modalRef.current) {
                isOpen ? disposeModal(successModalRef) : disposeModal(formRef);
            }
        }
    };

    window.addEventListener("scroll", scrollHandle);

    const { t } = useTranslation();

    return (
        <>
            <div
                onClick={displayHandle}
                ref={modalRef}
                className="waitlistform fixed top-0 left-0 flex justify-center items-center h-screen w-full min-w-[375px] z-50 backdrop-blur-sm animated fadeIn"
            >
                <div
                    ref={formRef}
                    className="waitlistform-container md:px-10 md:py-8 p-4 overflow-y-auto animated zoomIn"
                    style={{ animationDelay: "0.5s" }}
                >
                    <button
                        className="absolute md:right-10 right-3 md:top-8 top-3 waitlistform-close"
                        onClick={() => disposeModal(formRef)}
                    >
                        <span></span>
                        <span></span>
                    </button>
                    <H2_MD className="lg:!text-[68px] lg:!leading-[60px] text-center">
                        {t('JoinWaitlist')}
                    </H2_MD>
                    <P_SM className="text-center text-silver-sand lg:leading-6 leading-4">
                        {t('WaitlistDescription')}
                    </P_SM>
                    <Formik
                        initialValues={{
                            firstName: "",
                            lastName: "",
                            email: "",
                            phone: "",
                            topic,
                        }}
                        validationSchema={WaitlistValidate(t)}
                        onSubmit={onSubmit}
                    >
                        {({ errors, touched, isSubmitting, values }) => (
                            <Form className="md:mt-6 mt-3 grid grid-cols-2 md:gap-x-4 md:gap-y-8 gap-y-6 gap-3">
                                <WaitlistInput
                                    name="firstName"
                                    type="text"
                                    placeholder={t('Name')}
                                    onKeyUp={onChange}
                                    className={`col-span-1  ${(errors.firstName &&
                                        touched.firstName &&
                                        values.firstName !== "") ||
                                        (touched.firstName && error !== "")
                                        ? "is-invalid"
                                        : ""
                                        }`}
                                >
                                    {(errors.firstName &&
                                        touched.firstName &&
                                        values.firstName !== "") ||
                                        (touched.firstName && error !== "") ? (
                                        <div className="is-invalid-message">{errors.firstName}</div>
                                    ) : null}
                                </WaitlistInput>
                                <WaitlistInput
                                    name="lastName"
                                    type="text"
                                    placeholder={t('LName')}
                                    onKeyUp={onChange}
                                    className={`col-span-1  ${(errors.lastName &&
                                        touched.lastName &&
                                        values.lastName !== "") ||
                                        (touched.lastName && error !== "")
                                        ? "is-invalid"
                                        : ""
                                        }`}
                                >
                                    {(errors.lastName &&
                                        touched.lastName &&
                                        values.lastName !== "") ||
                                        (touched.lastName && error !== "") ? (
                                        <div className="is-invalid-message">{errors.lastName}</div>
                                    ) : null}
                                </WaitlistInput>
                                <WaitlistInput
                                    onKeyUp={onChange}
                                    className={`md:col-span-1 col-span-2  ${(errors.email && touched.email && values.email !== "") ||
                                        (touched.email && error !== "")
                                        ? "is-invalid"
                                        : ""
                                        }`}
                                    name="email"
                                    type="text"
                                    placeholder={t('Email')}
                                >
                                    {(errors.email && touched.email && values.email !== "") ||
                                        (touched.email && error !== "") ? (
                                        <div className="is-invalid-message">{errors.email}</div>
                                    ) : null}
                                </WaitlistInput>
                                <WaitlistInput
                                    onKeyUp={onChange}
                                    className={`md:col-span-1 col-span-2  ${(errors.phone && touched.phone && values.phone !== "") ||
                                        (touched.phone && error !== "")
                                        ? "is-invalid"
                                        : ""
                                        }`}
                                    name="phone"
                                    type="text"
                                    placeholder={t('Phone')}
                                >
                                    {(errors.phone && touched.phone && values.phone !== "") ||
                                        (touched.phone && error !== "") ? (
                                        <div className="is-invalid-message">{errors.phone}</div>
                                    ) : null}
                                </WaitlistInput>

                                <div className="col-span-2">
                                    <button
                                        className="waitlistform-send-btn text-center w-full font-heading md:h-[75px] h-12 md:rounded-2xl rounded-xl md:text-xl text-2xl-m text-black relative top-0 duration-300 hover:top-[-3px] outline-0 flex items-center justify-center"
                                        type="submit"
                                        disabled={isSubmitting || Object.keys(errors).length > 0 || !values.firstName || !values.lastName || !values.email || !values.phone}
                                    >
                                        {isSubmitting ? <img src={Loader} alt="" /> : t('Send')}
                                    </button>
                                    {error !== "" ? (
                                        <div className="is-invalid-message !relative !bottom-0 text-center mt-3">
                                            {error}
                                        </div>
                                    ) : null}
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                {isOpen ? (
                    <SuccessModalContainer
                        ref={successModalRef}
                        disposeModal={disposeModal}
                        title={t('WaitlistModal.Title')}
                        description={
                            <Fragment>
                                {t('WaitlistModal.Description')}{" "}
                            </Fragment>
                        }
                    />
                ) : null}
            </div>
        </>
    );
};

export default WaitlistForm;
