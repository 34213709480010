import {
  H2_MD,
  H3_LG,
  H3_MD,
  H3_SM,
  P_2MD,
  P_SM,
} from "../components/Typography";
import Bg from "../assets/images/benefits-bg.jpg";
import SmCircle from "../assets/images/sm-circle.svg";
import Circle from "../assets/images/circle.svg";
import LineCircle from "../assets/images/line-circle.png";
import { ReactComponent as Line1 } from "../assets/images/line-1.svg";
import { ReactComponent as Line11 } from "../assets/images/line-1-1.svg";
import { ReactComponent as Line2 } from "../assets/images/line-2.svg";
import { useParallax } from "react-scroll-parallax";
import { useInView } from "react-intersection-observer";
import useChangeWidth from "../hooks/useChangeWidth";
import { useTranslation } from "react-i18next";

const styles = {
  wrapper: {
    background: `url(${Bg}) no-repeat top center / cover`,
  },
  listItem: {
    background: `url(${SmCircle}) no-repeat left 6px`,
  },
};


const ListItem = ({ children, first }) => {
  return (
    <>
      <li
        className={`${!first ? "mt-5" : "mt-4"
          } font-medium text-base leading-5 pl-6`}
        style={styles.listItem}
      >
        {children}
      </li>
    </>
  );
};

const Benefits = () => {
  const { t } = useTranslation();

  const isSize = useChangeWidth(1280);
  const box1 = useParallax({
    speed: -2,
    onEnter: () => {
      box1.ref.current.firstElementChild.classList.add("animated");
      box1.ref.current.firstElementChild.classList.add("fadeInLeft");
    },
    disabled: !isSize,
  });
  const box2 = useParallax({
    speed: 2,
    onEnter: () => {
      box2.ref.current.firstElementChild.classList.add("animated");
      box2.ref.current.firstElementChild.classList.add("fadeInRight");
    },
    disabled: !isSize,
  });
  const box3 = useParallax({
    speed: 2,
    onEnter: () => {
      box3.ref.current.firstElementChild.classList.add("animated");
      box3.ref.current.firstElementChild.classList.add("fadeInUp");
    },
    disabled: !isSize,
  });

  const parallaxView1 = useInView({ threshold: 0.5, triggerOnce: true });
  const parallaxView1Box1 = useInView({
    threshold: isSize ? 0.5 : 0.2,
    triggerOnce: true,
  });
  const parallaxView1Box2 = useInView({
    threshold: isSize ? 0.5 : 0.2,
    triggerOnce: true,
  });
  const parallaxView1Box3 = useInView({
    threshold: isSize ? 0.5 : 0.2,
    triggerOnce: true,
  });

  return (
    <>
      <section
        className="benefits relative z-10 pb-[324px] pt-32 overflow-hidden before:gradient-3 before:absolute before:w-full before:left-0 before:top-0 before:h-[256px] before:-z-10 before:rotate-180 after:gradient-3 after:absolute after:w-full after:left-0 after:bottom-0 after:h-[940px] after:-z-10"
        style={styles.wrapper}
      >
        <div className="container">
          <div className="flex flex-col items-center" ref={parallaxView1.ref}>
            <H3_MD
              className={`${parallaxView1.inView ? "fadeInLeftO75" : "fadeOutLeft"
                } pt-32 gradient-2 gradient-text text-center animated`}
            >
              {t('COVENLABS_CLUB')}
            </H3_MD>
            <H2_MD
              className={`${parallaxView1.inView ? "fadeInRight" : "fadeOutRight"
                } text-center animated`}
            >
              {t('COVENLABS_CLUB_BENEFITS')}
            </H2_MD>
            <P_2MD
              className={`${parallaxView1.inView ? "fadeInUp" : "fadeOut"
                } mt-3 text-amethyst-smoke text-center animated animated-delay-2`}
            >
              {t('PastAndUpcomingProjects')} <br className="lg:block hidden" />
              {t('PastAndUpcomingProjects2')}
            </P_2MD>
          </div>
          <div className="max-w-6xl mt-10 mx-auto relative z-10">
            <div className="flex justify-between xl:items-start items-center xl:flex-row flex-col">
              <div ref={box1.ref} className="max-w-[460px] w-5/6">
                <div
                  ref={parallaxView1Box1.ref}
                  className={`${parallaxView1Box1.inView & !isSize ? "fadeInUp" : "fadeOut"
                    } w-full gradient-4 rounded-[40px] mt-7 animated`}
                  style={{ border: "1px solid rgba(255, 255, 255, 0.3)" }}
                >
                  <H3_SM className="pt-6 lg:px-8 px-5 pb-2 gradient-text gradient-7 border-b-[1px] border-river-bed">
                    {t('EVENTS')}
                  </H3_SM>
                  <ul className="lg:px-8 px-5 pb-8 text-amethyst-smoke">
                    <ListItem first={true}>
                      {t('EventDetails.Workshops')}
                    </ListItem>
                    <ListItem>
                      {t('EventDetails.TechShowcases')}
                    </ListItem>
                    <ListItem>
                      {t('EventDetails.InteractiveWorkshops')}
                    </ListItem>
                    <ListItem>
                      {t('EventDetails.VIPSpeakerSeries')}
                    </ListItem>
                    <ListItem>
                      {t('EventDetails.GlobalEventPrivileges')}
                    </ListItem>
                    <ListItem>
                      {t('EventDetails.EducationalTechTours')}
                    </ListItem>
                    <ListItem>
                      {t('EventDetails.CollaborativeHackathons')}
                    </ListItem>
                    <ListItem>
                      {t('EventDetails.TechExhibitions')}
                    </ListItem>
                    <ListItem>
                      {t('EventDetails.MusicFestivals')}
                    </ListItem>
                    <ListItem>
                      {t('EventDetails.ArtExhibitions')}
                    </ListItem>

                  </ul>
                </div>
              </div>
              <div className="xl:mt-[168px] mt-2">
                <div className="flex flex-col items-center">
                  {isSize ? (
                    <>
                      <div className="mb-[14px] mr-[93px]">
                        <Line1 />
                      </div>
                      <div className="ml-[92px]">
                        <Line11 />
                      </div>
                      <img src={Circle} className="mt-6 mb-2" alt="" />
                      <Line2 />
                    </>
                  ) : (
                    <Line2 />
                  )}
                </div>
              </div>
              <div ref={box2.ref} className="max-w-[460px] w-5/6">
                <div
                  ref={parallaxView1Box2.ref}
                  className={`${parallaxView1Box2.inView & !isSize ? "fadeInUp" : "fadeOut"
                    } w-full gradient-5 rounded-[40px] xl:mt-[107px] mt-5 animated`}
                  style={{ border: "1px solid rgba(255, 255, 255, 0.3)" }}
                >
                  <H3_SM className="pt-6 lg:px-8 px-5 pb-2 gradient-text gradient-7 border-b-[1px] border-river-bed">
                    {t('NETWORKING')}
                  </H3_SM>
                  <ul className="lg:px-8 px-5 pb-8 text-amethyst-smoke">
                    <ListItem first={true}>
                      {t('NetworkingDetails.ConnectWithIndustryLeaders')}
                    </ListItem>
                    <ListItem>
                      {t('NetworkingDetails.FastNetworking')}
                    </ListItem>
                    <ListItem>
                      {t('NetworkingDetails.ClubCelebrations')}
                    </ListItem>
                    <ListItem>
                      {t('NetworkingDetails.ProfessionalRecognition')}
                    </ListItem>
                    <ListItem>
                      {t('NetworkingDetails.NetworkingMixers')}
                    </ListItem>
                    <ListItem>
                      {t('NetworkingDetails.CulturalExperiences')}
                    </ListItem>
                  </ul>
                </div>
              </div>
              {!isSize && (
                <>
                  <div className="xl:mt-[168px] mt-2">
                    <div className="flex flex-col items-center">
                      <Line2 />
                    </div>
                  </div>
                </>
              )}
            </div>
            <img
              src={LineCircle}
              className="absolute top-0 left-[calc(50%-1636px)] mx-auto -z-10 opacity-25 rotateInfinite max-w-none"
              alt=""
            />
            <div ref={box3.ref} className="max-w-[542px] w-full mx-auto">
              <div
                ref={parallaxView1Box3.ref}
                className={`${parallaxView1Box3.inView & !isSize ? "fadeInUp" : "fadeOut"
                  } w-full gradient-6 rounded-[40px] mt-4 text-center animated`}
                style={{ border: "1px solid rgba(255, 255, 255, 0.5)" }}
              >
                <H3_LG className="pt-7 lg:px-8 px-5 pb-5 gradient-text gradient-7 border-b-[1px] border-river-bed">
                  {t('COVENLABS_CLUB_HOUSE')}
                </H3_LG>
                <div className="sm:px-20 lg:px-8 px-5 pb-12 gradient-text gradient-7">
                  <P_SM className="mt-8">
                    {t('ClubHouseDetails.Soon')}
                  </P_SM>
                  <P_SM className="mt-5">
                    {t('ClubHouseDetails.World')}
                  </P_SM>
                  <P_SM className="mt-5">
                    {t('ClubHouseDetails.Person')}
                  </P_SM>
                </div>
              </div>
              {/* <div ref={parallaxView2.ref}>
                <H2_MD
                  className={`${parallaxView2.inView ? "fadeInUp" : "fadeOut"
                    } mt-48 text-center animated`}
                >
                  COLLABORATIONS
                </H2_MD>
                <P_2MD
                  className={`${parallaxView2.inView ? "fadeInUp" : "fadeOut"
                    } mt-3 text-amethyst-smoke text-center animated animated-delay-2`}
                >
                  Current and upcoming project collaborations will be available
                  exclusively to the CovenLabs Club Community very soon. Hold on
                  to your seats!
                </P_2MD>
              </div> */}
            </div>
          </div>
          {/* <div className="relative z-10">
            <div ref={parallaxView3.ref}>
              <H2_MD
                className={`${parallaxView3.inView ? "fadeInUp" : "opacity-0"
                  } mt-48 text-center animated`}
              >
                ROADMAP
              </H2_MD>
            </div>
            <div className="flex xl:flex-row flex-col items-center justify-center mt-8 gap-y-12">
              <div
                ref={parallaxView3Box1.ref}
                className={`${parallaxView3Box1.inView
                  ? `${isSize ? "fadeInRight50" : "fadeInUp"}`
                  : "opacity-0"
                  } xl:w-[407px] xl:h-[392px] w-[369px] h-[342px] relative z-10 flex flex-col xl:justify-start justify-center pb-7 2xl:pt-[70px] xl:pt-14 p-0 xl:text-left text-center xl:pl-8 xl:pr-20 animated animated-delay-4`}
              >
                <H3_LG className="gradient-text gradient-7">LAUNCH</H3_LG>
                <H3_MD className="gradient-8 gradient-text">GENESIS</H3_MD>
                <P_MD className="mt-3 text-amethyst-smoke !leading-6">
                  Releasing the teaser
                </P_MD>
                <P_MD className="mt-6 text-amethyst-smoke !leading-6">
                  Community Building & Marketing Campaigns
                </P_MD>
                <P_MD className="mt-6 text-amethyst-smoke !leading-6">
                  Expansion of <br />
                  Collaborations
                </P_MD>
                <img
                  src={RoadmapL}
                  alt=""
                  className="absolute -z-10 inset-0 xl:rotate-0 rotate-90"
                />
              </div>
              <div
                ref={parallaxView3Box2.ref}
                className={`${parallaxView3Box2.inView
                  ? `${isSize ? "fadeIn" : "fadeInUp"}`
                  : "opacity-0"
                  } xl:w-[511px] xl:h-[392px] w-[446px] h-[342px] relative z-10 flex flex-col xl:justify-start justify-center 2xl:pt-[70px] xl:pt-14 px-8 text-center animated animated-delay-2`}
              >
                <H3_LG className="gradient-text gradient-9">
                  COVENLABS CLUB
                </H3_LG>
                <H3_MD className="text-dolphin">GROWTH</H3_MD>
                <P_MD className="mt-8 text-blue-charcoal !leading-6">
                  NFTs Creation
                </P_MD>
                <P_MD className="mt-6 text-blue-charcoal !leading-6">
                  Private & Public Tours
                </P_MD>
                <P_MD className="mt-6 text-blue-charcoal !leading-6">
                  Starting Private Events
                </P_MD>
                <img
                  src={RoadmapC}
                  alt=""
                  className="absolute -z-10 inset-0 xl:rotate-0 rotate-90"
                />
              </div>
              <div
                ref={parallaxView3Box3.ref}
                className={`${parallaxView3Box3.inView
                  ? `${isSize ? "fadeInLeft50" : "fadeInUp"}`
                  : "opacity-0"
                  } xl:w-[407px] xl:h-[392px] w-[369px] h-[342px] relative z-10 flex flex-col 2xl:pt-[70px] xl:pt-14 xl:pl-20 xl:pr-8 p-0 xl:justify-start justify-center pt-10 xl:text-right text-center animated animated-delay-4`}
              >
                <H3_LG className="gradient-text gradient-7">
                  DAO INTEGRATION
                </H3_LG>
                <H3_MD className="gradient-8 gradient-text">EVOLVING</H3_MD>
                <P_MD className="mt-3 text-amethyst-smoke !leading-6">
                  DAO Creation
                </P_MD>
                <P_MD className="mt-6 text-amethyst-smoke !leading-6">
                  Announcing the <br />
                  Covenlabs Club House
                </P_MD>
                <P_MD className="mt-6 text-amethyst-smoke !leading-6">
                  Introducing Upcoming <br />
                  Projects
                </P_MD>
                <img
                  src={RoadmapR}
                  alt=""
                  className="absolute -z-10 inset-0 xl:rotate-0 rotate-90"
                />
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Benefits;
