export const waitlist = {
  api: process.env.REACT_APP_WAITLIST_API,
  loading: "Submit Form...",
  success: "Submission Successful!",
  error: "Submission Failed!",
  validation: {
    email: "Invalid Email!",
  },
  required: {
    email: "Email Required!",
  },
};

export const subscribe = {
  api: process.env.REACT_APP_SUBSCRIBE_API,
  loading: "Submit Form...",
  success: "Submission Successful!",
  error: "Submission Failed!",
  validation: {
    email: "Invalid Email!",
  },
  required: {
    email: "Email Required!",
  },
};
