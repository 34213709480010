import { H2_MD, H3_MD, P_MD } from "../components/Typography";
import Nftcard from "../assets/videos/nft-card.mp4";
import { useParallax } from "react-scroll-parallax";
import { useInView } from "react-intersection-observer";
import useChangeWidth from "../hooks/useChangeWidth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import WaitlistForm from "../components/waitlist/Waitlist";

const Genesis = () => {
  const isSize = useChangeWidth(1280);
  const video = useParallax({
    speed: 12,
    disabled: !isSize,
  });
  const [isOpen, setIsOpen] = useState(false);
  //const modalHandle = () => setIsOpen(true);
  const closeModalState = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 800);
  };

  const sections = useInView({ threshold: 0.5, triggerOnce: true });

  const { t } = useTranslation();

  return (
    <>
      <section className="bg-blue-charcoal">
        <div className="container">
          <div className="xl:grid xl:grid-cols-2 flex flex-col xl:text-left text-center xl:max-w-none max-w-2xl mx-auto">
            <div className="col-span-1">
              <video
                className="xl:max-w-none max-w-lg mx-auto w-full"
                ref={video.ref}
                src={Nftcard}
                muted
                autoPlay
                loop
                playsInline
              />
            </div>
            <div className="col-span-1 flex flex-col justify-center">
              <div
                className="flex flex-col xl:items-start items-center"
                ref={sections.ref}
              >
                <H3_MD
                  className={`${sections.inView ? "fadeInLeftO75" : "fadeOutLeft"
                    } gradient-2 gradient-text animated`}
                >
                  {t('COVENLABS CLUB')}
                </H3_MD>
                <H2_MD
                  className={`${sections.inView ? "fadeInRight" : "fadeOutRight"
                    } animated`}
                >
                  {t('COVENLABS_CLUB_MEMBERSHIP')}
                </H2_MD>
                <P_MD
                  className={`${sections.inView ? "fadeInUp" : "fadeOut"
                    } mt-3 text-amethyst-smoke animated animated-delay-2`}
                >
                  {t('ClubMembershipDetails')}
                </P_MD>
                <P_MD
                  className={`${sections.inView ? "fadeInUp" : "fadeOut"
                    } mt-6 text-amethyst-smoke animated animated-delay-3`}
                >
                  {t('ClubMembershipDetails2')}
                </P_MD>
                <P_MD
                  className={`${sections.inView ? "fadeInUp" : "fadeOut"
                    } mt-6 text-amethyst-smoke animated animated-delay-4`}
                >
                  {t('ClubMembershipDetails3')}
                </P_MD>
                {/* <EmailForm
                  className={`${sections.inView ? "fadeInUp" : "fadeOut"
                    } mt-12 animated animated-delay-5 xl:w-[592px] w-full`}
                  config={waitlist}
                  btnText={t('JoinWaitlist')}
                  btnClass={`lg:h-[90px] h-[62px]`}
                  modalHandle={modalHandle}
                /> */}
              </div>
              <button
                type={"submit"}
                className={`${sections.inView ? "fadeInUp" : "fadeOut"} animated animated-delay-5 relative right-0 mr-[1px] mt-[1px] invest-button inline-flex lg:rounded-[30px] rounded-[20px] cursor-pointer top-0 text-center justify-center lg:text-[27px] text-lg leading-[34px] z-[1] overflow-hidden lg:h-[90px] h-[62px]`}
                style={{ borderWidth: "0 0 0 1px" }}
                onClick={() => setIsOpen(true)}
              >
                <span className="font-heading">
                  {t('JoinWaitlist')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </section >
      {
        isOpen ? (
          // <SuccessModal
          //   closeModalState={closeModalState}
          //   title="Welcome!"
          //   description="You're on the list! We'll keep you updated."
          // />
          <WaitlistForm
            closeModalState={closeModalState}
          />
        ) : null
      }
    </>
  );
};

export default Genesis;
