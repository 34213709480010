export const H2_LG = ({ children, className, style }) => {
  return (
    <>
      <h2
        className={`font-heading 2xl:text-4xl md:text-4xl-t text-4xl-m  ${
          className || ""
        }`}
        style={{ ...style }}
      >
        {children}
      </h2>
    </>
  );
};

export const H2_MD = ({ children, className, style }) => {
  return (
    <>
      <h2
        className={`font-heading 2xl:text-3xl md:text-3xl-t text-3xl-m ${
          className || ""
        }`}
        style={{ ...style }}
      >
        {children}
      </h2>
    </>
  );
};

export const H3_LG = ({ children, className, style }) => {
  return (
    <>
      <h3
        className={`font-heading 2xl:text-2xl text-2xl-t ${className || ""}`}
        style={{ ...style }}
      >
        {children}
      </h3>
    </>
  );
};

export const H3_MD = ({ children, className, style }) => {
  return (
    <>
      <h3
        className={`font-heading 2xl:text-xl lg:text-xl-t text-xl-m ${
          className || ""
        }`}
        style={{ ...style }}
      >
        {children}
      </h3>
    </>
  );
};

export const H3_SM = ({ children, className, style }) => {
  return (
    <>
      <h3
        className={`font-heading 2xl:text-2lg lg:text-2lg-t text-2lg-m ${
          className || ""
        }`}
        style={{ ...style }}
      >
        {children}
      </h3>
    </>
  );
};

export const P_LG = ({ children, className, style }) => {
  return (
    <>
      <p
        className={`lg:text-lg text-2md-m font-medium ${className || ""}`}
        style={{ ...style }}
      >
        {children}
      </p>
    </>
  );
};

export const P_2MD = ({ children, className, style }) => {
  return (
    <>
      <p
        className={`md:m-auto md:mt-5 md:w-2/3 lg:text-2md text-2md-m font-medium text-silver-sand ${
          className || ""
        }`}
        style={{ ...style }}
      >
        {children}
      </p>
    </>
  );
};

export const P_MD = ({ children, className, style }) => {
  return (
    <>
      <p
        className={`lg:text-md text-base font-medium ${className || ""}`}
        style={{ ...style }}
      >
        {children}
      </p>
    </>
  );
};

export const P_SM = ({ children, className, style }) => {
  return (
    <>
      <p
        className={`text-base font-medium ${className || ""}`}
        style={{ ...style }}
      >
        {children}
      </p>
    </>
  );
};
