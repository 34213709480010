import { ReactComponent as Twitter } from "../assets/images/ic-twitter.svg";
import { ReactComponent as Instagram } from "../assets/images/ic-instagram.svg";
import { ReactComponent as Linkedin } from "../assets/images/ic-linkedin.svg";
// import { ReactComponent as Discord } from "../assets/images/ic-discord.svg";
import { ReactComponent as Telegram } from "../assets/images/ic-telegram.svg";
import { ReactComponent as Medium } from "../assets/images/ic-medium.svg";


const SocialMedia = ({ size, gap }) => {
  return (
    <>
      <div className={`flex ${gap}`}>
        <a
          className="relative top-0 hover:top-[-4px] duration-300"
          href="https://x.com/covenlabsclub?s=11&t=JMpvrDj6wDrjP-76hpx5pQ"
          alt="Covenlabs Twitter"
          target="_blank"
          rel="noreferrer"
        >
          <Twitter className={`${size}`} />
        </a>
        <a
          className="relative top-0 hover:text-[#fb3958] hover:top-[-4px] duration-300"
          href="https://www.instagram.com/covenlabsclub?igsh=Znl5d3Q5YmI3ODNw&utm_source=qr"
          alt="Covenlabs Instagram"
          target="_blank"
          rel="noreferrer"
        >
          <Instagram className={`${size}`} />
        </a>
        <a
          className="relative top-0 hover:text-[#0077b5] hover:top-[-4px] duration-300"
          href="https://www.linkedin.com/company/covenlabsclub/"
          alt="Covenlabs Linkedin"
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin className={`${size}`} />
        </a>
        {/* <a
          className="relative top-0 hover:text-[#7289d9] hover:top-[-4px] duration-300"
          href="discord.com"
          alt="Covenlabs Discord"
          target="_blank" rel="noreferrer"
        >
          <Discord className={`${size}`} />
        </a> */}
        <a
          className="relative top-0 hover:text-[#0088cc] hover:top-[-4px] duration-300"
          href="https://t.me/+WFr7FLbJt81iODhk"
          alt="Covenlabs Telegram"
          target="_blank"
          rel="noreferrer"
        >
          <Telegram className={`${size}`} />
        </a>
        <a
          className="relative top-0 hover:text-[#66cdaa] hover:top-[-4px] duration-300"
          href="https://medium.com/@covenlabsclub"
          alt="Covenlabs Medium"
          target="_blank"
          rel="noreferrer"
        >
          <Medium className={`${size}`} />
        </a>
      </div>
    </>
  );
};

export default SocialMedia;
