import useChangeWidth from "../../hooks/useChangeWidth";
import { P_SM } from "../../components/Typography";
import SocialMedia from "../../components/SocialMedia";
import EmailForm from "../../components/email/Email";
import { subscribe } from "../../constants";
import "./Footer.scss";
import SuccessModal from "../../components/successModal/SuccessModal";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const isSize = useChangeWidth(1024);
  const [isOpen, setIsOpen] = useState(false);
  const modalHandle = () => setIsOpen(true);
  const closeModalState = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 800);
  };

  const { t } = useTranslation();

  return (
    <>
      <footer>
        <div className="container flex items-center justify-center">
          <div className="footer-container w-full lg:grid 2xl:gap-24 gap-12 xl:pt-14 lg:pt-16 pt-6 xl:pb-20 lg:pb-16 pb-6">
            <div className="separator flex items-center justify-between lg:pb-0 pb-6">
              <a href="/">
                <img
                  className="duration-500 lg:w-[170px] w-[97px]"
                  src={require(`../../assets/images/logo.png`)}
                  alt="Covenlabs"
                  srcSet={`${require(`../../assets/images/logo.png`)} 1x, ${require(`../../assets/images/logo@2x.png`)} 2x`}
                />
              </a>
              {!isSize && (
                <SocialMedia
                  size="2xl:w-[32px] xl:w-[24px] w-[16px]"
                  gap="2xl:gap-7 lg:gap-3 gap-4"
                />
              )}
            </div>
            <div className="separator lg:py-0 py-6">
              <span className="font-heading 2xl:text-xl xl:text-xl-t text-xl-m">
                {t('ContactUs')}
              </span>
              <P_SM>
                <a
                  href="mailto:info@covenlabs.club"
                  className="xl:mt-5 lg:mt-4 mt-0 block text-[#808080] hover:text-white duration-300 font-medium"
                >
                  info@covenlabsclub.com
                </a>
              </P_SM>
            </div>
            <div className="lg:flex justify-between items-center lg:gap-6 lg:pt-0 pt-6">
              <div className="w-full">
                <span className="font-heading 2xl:text-xl xl:text-xl-t text-xl-m">
                  {t('SubscribeTitle')}
                </span>
                <div className="mt-1">
                  <EmailForm
                    config={subscribe}
                    btnText={t('Subscribe')}
                    btnClass="footarea xl:h-[62px] lg:h-[50px] h-[46px]"
                    inputClass="but-footarea"
                    modalHandle={modalHandle}
                  />
                </div>
              </div>
              <div className="flex flex-col lg:items-end items-center xl:gap-5 gap-3 lg:mt-auto mt-6">
                {isSize && (
                  <SocialMedia
                    size="2xl:w-[32px] xl:w-[24px] w-[16px]"
                    gap="2xl:gap-7 lg:gap-5 gap-4"
                  />
                )}
                <span className="font-heading text-base text-silver-sand leading-4">
                  © 2022 CovenLabs
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {isOpen ? (
        <SuccessModal
          closeModalState={closeModalState}
          title={t('SubscribeModal.Title')}
          description={
            <Fragment>
              {t('SubscribeModal.Description')}{" "}
              <br className="md:block hidden" />
              {t('SubscribeModal.Description2')}
            </Fragment>
          }
        />
      ) : null}
    </>
  );
};

export default Footer;
