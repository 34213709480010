import * as Yup from "yup";

const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

const WaitlistValidate = (t) =>
    Yup.object().shape({
        firstName: Yup.string()
            .min(2, t("Validation.Name1") + t("Validation.NameMin"))
            .max(50, t("Validation.Name1") + t("Validation.NameMax"))
            .required(t("Validation.Name1") + t("Validation.Required")),
        lastName: Yup.string()
            .min(2, t("Validation.LName1") + t("Validation.NameMin"))
            .max(50, t("Validation.LName1") + t("Validation.NameMax"))
            .required(t("Validation.LName1") + t("Validation.Required")),
        email: Yup.string()
            .email(t("Validation.Email"))
            .required(t("Validation.Email1") + t("Validation.Required")),
        phone: Yup.string()
            .matches(phoneRegExp, t("Validation.Phone"))
            .required(t("Validation.Phone1") + t("Validation.Required")),
    });


export default WaitlistValidate;
